export const theme = {
  fontSizes: [12, 14, 16, 24, 32, 48, 64, 96, 128],
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    textPrimary: '#36383b',
    textSecondary: '#ffffff',
    primary: '#11dfb8',
    babyPurple: 'rgb(174, 143, 255)',
    green: '#11dfb8',
    footer: '#202122'
  },
  fontFamily: {
    Baloo: 'Baloo Thambi',
    Montserrat: 'Montserrat'
  }
}

theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]
theme.mediaQueries = {
  sm: `@media screen and (max-width: ${theme.breakpoints[0]})`,
  md: `@media screen and (max-width: ${theme.breakpoints[1]})`,
  lg: `@media screen and (min-width: ${theme.breakpoints[3]})`
}
