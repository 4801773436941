import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'

import { theme } from '../theme'

const globalStyles = css`
  ${emotionNormalize}
  html,
  body {
    padding: 0;
    margin: 0;
    color: ${theme.colors.textPrimary};
    font-family: Montserrat;
    line-height: 1.2;

    @media only screen and (max-width: 64em) {
      overflow-x: hidden;
    }
  }

  * {
    box-sizing: border-box;
  }
`

export const GlobalStyleWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        ${globalStyles}
      `}
    />
    {children}
  </ThemeProvider>
)

GlobalStyleWrapper.propTypes = {
  children: PropTypes.node
}

export default GlobalStyleWrapper
